<template>
  <div>
    <!-- Loading -->
    <div v-if="isLoading" class="text-center">
      <spinner></spinner>
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">

      <wizard-progress :current-stage="stage" @set-wizard-stage="setWizardStage"></wizard-progress>

      <!-- Stage 0: Quote Type -->
      <div v-if="stage === 0"
           class="bg-light rounded-3 p-4 p-md-5 mb-3">
        <!-- Title -->
        <h2 class="h4 mb-5 text-center">
          <!--          <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
          Please choose a quote type
        </h2>
        <!-- / Title -->

        <div class="alert alert-info mb-4" role="alert">
          <i class="fi-info-circle me-2"></i>
          To propose initial calls or no-cost services, please select "Other"
        </div>

        <div class="row">
          <div class="col-md-3 mb-3" v-for="quoteType in quoteTypes" :key="quoteType.value">
            <quote-type-card :quote-type="quoteType"
                             @selected="() => handleQuoteTypeSelected(quoteType.value)">
            </quote-type-card>
          </div>
          <div class="text-center mt-4">
            Do you have enough information to quote? If not then why not <a href="#" @click.prevent="$emit('chat-to-client')">chat to {{ job.client_name }}</a>?
          </div>
        </div>
      </div>
      <!-- / Stage 0: Quote Type -->

      <!-- Stage 1: Quote Details -->
      <quote-details v-if="stage === 1"
                     :is-saving="isSaving"
                     @go-back="goBack"
                     @go-forward="goForward"
                     :quote="quote"></quote-details>
      <!-- / Stage 1: Quote Details -->

      <!-- Stage 2: Notes for client -->
      <notes-for-client v-if="stage === 2"
                        @go-back="goBack"
                        @go-forward="goForward"
                        :quote="quote"></notes-for-client>
      <!-- / Stage 2: Notes for client -->

      <!-- Review -->
      <review v-if="stage === 3" @go-back="goBack"
              :is-saving="isSaving"
              :quote="quote"
              @submit-quote="submitQuote"></review>
      <!-- / Review -->


      <!--      <div class="mt-4">-->
      <!--        <button class="btn btn-primary"-->
      <!--                :class="{'opacity-50': (isSaving||!canSave)}"-->
      <!--                @click.prevent="submitQuote"-->
      <!--                :disabled="(isSaving||!canSave)"-->
      <!--                type="submit">-->
      <!--          <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>-->
      <!--          Submit Quote-->
      <!--        </button>-->
      <!--      </div>-->

    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Spinner from "../../../components/Spinner";
import axios from 'axios';
import {useToast} from 'vue-toastification';
import Forms from "../../../mixins/Forms";
import company from "../../../api/company";
import QuoteTypeCard from "./ProvideQuoteForm/QuoteTypeCard";
import WizardProgress from "./ProvideQuoteForm/WizardProgress";
import QuoteDetails from "./ProvideQuoteForm/QuoteDetails";
import NotesForClient from "./ProvideQuoteForm/NotesForClient";
import Review from "./ProvideQuoteForm/Review";

const toast = useToast();

export default {
  name: "ProvideQuoteForm",
  props: ['job'],
  mixins: [Forms],
  components: {Review, QuoteDetails, WizardProgress, QuoteTypeCard, Spinner, NotesForClient},
  created() {
    this.quote.hourlyRate = this.userRole.hourly_rate ? this.userRole.hourly_rate / 100 : null;
    this.fetchDefaultPostJobAcceptanceMessage();
  },
  data() {
    return {
      isLoading: false,
      stage: 0,

      quoteTypes: [
        {
          label: 'Hourly Rate',
          value: 'hourly',
          icon: 'fi-alarm'
        },
        {
          label: 'Fixed Price',
          value: 'fixed-price',
          icon: 'fi-check'
        },
        {
          label: 'Monthly',
          value: 'monthly-fee',
          icon: 'fi-calendar'
        },
        {
          label: 'Other',
          value: 'other',
          icon: 'fi-info-circle'
        }
      ],

      quote: {
        quoteType: 'hourly', // hourly or fixed price or monthly

        hourlyRate: null,
        estimatedHours: null,

        fixedPrice: null,

        monthlyFee: null,
        startDate: null,
        minimumTermMonths: null,

        depositRequired: 0,

        vatRate: '20',

        notes: '',
        post_job_acceptance_message: '',

        estimatedCompletionDate: null,

        quoteTerms: ''
      },
      isSaving: false,
      serverErrors: []
    }
  },
  methods: {
    handleQuoteTypeSelected(quoteType) {
      this.quote.quoteType = quoteType;
      this.stage += 1;
    },

    setWizardStage(i) {
      this.stage = i;
    },

    goBack() {
      this.stage -= 1;
    },

    goForward() {
      this.stage += 1;
    },

    fetchDefaultPostJobAcceptanceMessage() {
      let vm = this;
      vm.isLoading = true;
      company.getDefaultPostJobAcceptanceMessage().then(r => {
        vm.isLoading = false;
        vm.quote.post_job_acceptance_message = r.data;
      }).catch(e => {
        console.log(e);
        vm.isLoading = false;
        vm.quote.post_job_acceptance_message = '';
      });
    },

    getParams() {
      let params = {
        quote_type: this.quote.quoteType,
        total_fee: this.totalFee * 100,
        deposit_required: this.quote.depositRequired * 100,
        vat_rate: this.quote.vatRate,
        notes: this.quote.notes,
        post_job_acceptance_message: this.quote.post_job_acceptance_message,
        estimated_completion_date: this.quote.estimatedCompletionDate
      };

      if (this.quote.quoteType === 'hourly') {
        params['hourly_rate'] = this.quote.hourlyRate * 100;
        params['estimated_hours'] = this.quote.estimatedHours;
      } else if (this.quote.quoteType === 'fixed-price') {
        params['fixed_price'] = this.quote.fixedPrice * 100;
      } else if (this.quote.quoteType === 'monthly-fee') {
        params['monthly_fee'] = this.quote.monthlyFee * 100;
        params['start_date'] = this.quote.startDate;
        params['minimum_term_months'] = this.quote.minimumTermMonths;
      } else if (this.quote.quoteType === 'other') {
        params['quote_terms'] = this.quote.quoteTerms;
        params['total_fee'] = 0;
        params['deposit_required'] = 0;
        params['vat_rate'] = 0;
      }

      return params;
    },

    submitQuote() {
      if (!this.canSave) {
        return false;
      }

      let vm = this;
      vm.isSaving = true;

      axios.post(window.API_BASE + '/jobs/' + this.job.id + '/quotes', this.getParams()).then(r => {
        vm.isSaving = false;
        toast.success('Quote submitted successfully');
        // vm.$router.push('/jobs/' + this.job.id);
        vm.$emit('quote-submitted');
      }).catch(e => {
        vm.isSaving = false;
        vm.setAndNotifyErrors(e, 'Error submitting quote');
      });
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    totalFee() {
      let totalFee = null;
      if (this.quote.quoteType === 'hourly') {
        totalFee = this.quote.estimatedHours * this.quote.hourlyRate;
      } else if (this.quote.quoteType === 'fixed-price') {
        totalFee = this.quote.fixedPrice;
      } else if (this.quote.quoteType === 'monthly-fee') {
        totalFee = this.quote.monthlyFee * this.quote.minimumTermMonths;
      } else if (this.quote.quoteType === 'other') {
        totalFee = 0;
      }
      return totalFee;
    },

    canSave() {
      if (this.quote.depositRequired === null || this.quote.depositRequired === '') {
        return false;
      }
      if (this.quote.quoteType === 'hourly') {
        return (this.quote.estimatedHours && this.quote.hourlyRate);
      }
      if (this.quote.quoteType === 'fixed-price') {
        return this.quote.fixedPrice;
      }
      if (this.quote.quoteType === 'monthly-fee') {
        return (this.quote.monthlyFee && this.quote.startDate && this.quote.minimumTermMonths);
      }
      if (this.quote.quoteType === 'other') {
        return this.quote.quoteTerms;
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>