<template>
  <div>
    <div class="bg-light rounded-3 p-4 p-md-5 mb-3">

      <!-- Title -->
      <h2 class="h4 mb-5 text-center">
<!--        <i class="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>-->
        Please provide the quote details
      </h2>
      <!-- / Title -->

      <!-- Hourly -->
      <div v-if="quote.quoteType === 'hourly'">
        <div class="row">
          <div class="col-md-6">
            <!-- Hourly Rate -->
            <div class="mb-4">
              <label class="form-label fw-bold" for="hourly-rate">Hourly Rate (£)</label>
              <input :disabled="isSaving" v-model="quote.hourlyRate"
                     class="form-control" type="number" step="0.01" id="hourly-rate">
              <div class="form-text">Please provide excluding VAT</div>
            </div>
            <!-- / Hourly Rate -->
          </div>
          <div class="col-md-6">
            <!-- Estimated Hours -->
            <div class="mb-4">
              <label class="form-label fw-bold" for="estimated-hours">Estimated Hours</label>
              <input :disabled="isSaving" v-model="quote.estimatedHours"
                     class="form-control" type="number" step="0.01" id="estimated-hours">
            </div>
            <!-- / Estimated Hours -->
          </div>
        </div>
      </div>
      <!-- / Hourly -->


      <!-- Fixed Price -->
      <div v-if="quote.quoteType === 'fixed-price'">
        <div class="mb-4">
          <label class="form-label fw-bold" for="fixed-price">Fixed Price (£)</label>
          <input :disabled="isSaving" v-model="quote.fixedPrice"
                 class="form-control" type="number" step="0.01" id="fixed-price">
          <div class="form-text">Please provide excluding VAT</div>
        </div>
      </div>
      <!-- / Fixed Price -->

      <!-- Monthly -->
      <div v-if="quote.quoteType === 'monthly-fee'">
        <!-- Monthly fee -->
        <div class="mb-4">
          <label class="form-label fw-bold" for="monthly-fee">Monthly Fee (£)</label>
          <input :disabled="isSaving" v-model="quote.monthlyFee"
                 class="form-control" type="number" step="0.01" id="monthly-fee">
          <div class="form-text">Please provide excluding VAT</div>
        </div>
        <!-- / Monthly fee -->

        <div class="row">
          <div class="col-md-6">
            <!-- Start date -->
            <div class="mb-4">
              <label class="form-label fw-bold" for="start-date">Start Date</label>
              <input :disabled="isSaving" v-model="quote.startDate"
                     class="form-control" type="date" id="start-date">
            </div>
            <!-- / Start date -->
          </div>
          <div class="col-md-6">
            <!-- Minimum term (months) -->
            <div class="mb-4">
              <label class="form-label fw-bold" for="minimum-term-months">Minimum Term (Months)</label>
              <input :disabled="isSaving" v-model="quote.minimumTermMonths"
                     class="form-control" type="number" step="1" id="minimum-term-months">
            </div>
            <!-- / Minimum term (months) -->
          </div>
        </div>
      </div>
      <!-- / Monthly -->

      <!-- Other -->
      <div v-if="quote.quoteType === 'other'">
        <div class="mb-4">
          <label class="form-label fw-bold" for="quote-terms">Proposed Terms</label>
          <textarea :disabled="isSaving" rows="4" v-model="quote.quoteTerms" class="form-control" id="quote-terms"></textarea>
        </div>
      </div>
      <!-- / Other -->

      <!-- Deposit and VAT Rate -->
      <div v-if="quote.quoteType !== 'other'" class="row">
        <div class="col-md-6">
          <!-- Deposit required -->
          <div class="mb-4">
            <label class="form-label fw-bold" for="deposit-required">Deposit Required (£)</label>
            <input :disabled="isSaving" v-model="quote.depositRequired"
                   class="form-control" type="number" step="0.01" id="deposit-required">
            <div class="form-text">Please provide excluding VAT</div>
          </div>
          <!-- / Deposit required -->
        </div>
        <div class="col-md-6">
          <!-- VAT rate -->
          <div class="mb-4">
            <label class="form-label fw-bold" for="vat-rate">VAT Rate</label>
            <select v-model="quote.vatRate" class="form-control">
              <option :value="20">Standard Rate - 20%</option>
              <option :value="5">Reduced Rate - 5%</option>
              <option :value="0">Zero Rate - 0%</option>
            </select>
          </div>
          <!-- / VAT rate -->
        </div>
      </div>
      <!-- / Deposit and VAT Rate -->

      <!-- Estimated Completion Date -->
      <div v-if="quote.quoteType !== 'other'">
        <div class="mb-4">
          <label class="form-label fw-bold" for="estimated-completion-date">Estimated Completion Date</label>
          <input :disabled="isSaving" v-model="quote.estimatedCompletionDate"
                 class="form-control" type="date" id="estimated-completion-date">
          <div class="form-text">
            If you win this work then the estimated completion date is pushed back based on the date won.
          </div>
        </div>
      </div>
      <!-- / Estimated Completion Date -->

    </div>

    <!-- Footer Navigation -->
    <div class="wizard-footer-navigation">
      <!-- Previous -->
      <a class="btn btn-primary btn-lg rounded-pill me-3" href="#"
         @click.prevent="handlePreviousButton">
        <i class="fi-chevron-left fs-sm me-2"></i>
        Previous
      </a>
      <!-- / Previous -->

      <!-- Next -->
      <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#"
         @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">
        Next
        <i class="fi-chevron-right fs-sm ms-2"></i>
      </a>
      <!-- / Next -->
    </div>
    <!-- / Footer Navigation -->
  </div>
</template>

<script>
import {useToast} from 'vue-toastification';
const toast = useToast();
export default {
  name: "QuoteDetails",
  props: ['quote', 'isSaving'],
  computed: {
    canGoForward() {
      if (this.quote.depositRequired === null || this.quote.depositRequired === '') {
        return false;
      }
      if (this.quote.quoteType === 'hourly') {
        return (this.quote.estimatedHours && this.quote.hourlyRate);
      }
      if (this.quote.quoteType === 'fixed-price') {
        return this.quote.fixedPrice;
      }
      if (this.quote.quoteType === 'monthly-fee') {
        return (this.quote.monthlyFee && this.quote.startDate && this.quote.minimumTermMonths);
      }
      if (this.quote.quoteType === 'other') {
        return this.quote.quoteTerms;
      }
      return false;
    }
  },
  methods: {
    handlePreviousButton() {
      this.$emit('go-back');
    },

    handleNextButton() {
      if (this.quote.estimatedCompletionDate) {
        let dateMoment = window.moment(this.quote.estimatedCompletionDate);
        let isValid = dateMoment.isValid();
        if (!isValid) {
          toast.error('Completion data invalid');
          return false;
        }
        if (dateMoment.endOf('day').isBefore(window.moment().startOf('day'))) {
          toast.error('Estimated completion date cannot be in past');
          return false;
        }
      }

      this.$emit('go-forward');
    }
  }
}
</script>

<style scoped>

</style>